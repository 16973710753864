import React from 'react'
import wandering from '../assets/Wandering.png'
import getFit from '../assets/Get-Fit-App.png'
import dashboard from '../assets/Dashboard.png'
import weather from '../assets/WeatherApp.png'
import crypto from '../assets/cr.png'
import socialize from '../assets/Socialize.png'

const Work = () => {
  return (
    <div name='work' className='w-full md:h-screen text-gray-300 z'>
        <div className='max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full'>
            <div className='pb-8'>
                <p className='text-4xl font-bold inline border-b-4 text-gray-300 border-pink-600'>Projects</p>
                <p className='py-6'>Check out some of my recent work</p>
            </div>
        {/* Container */}
            <div className='grid sm:grid-cols-2 md:grid-cols-3 gap-4'>

                {/* Grid Item */}

                <div style={{backgroundImage: `url(${crypto})`}}
                className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div'>

                    {/* Hover Effect */}
                    <div className='opacity-0 group-hover:opacity-100'>
                    <span className='text-2xl font-bold text-white tracking-wider'>
                       Crypto-Buddy
                       </span>
                       <br></br>
                       <span className='text-sm text-white tracking-wider'>
                        JavaScript/React Application
                        </span>
                        <div className='pt-8 text-center'>
                            <a href="https://crypto-buddy.online/">
                                <button className='text-center rounde-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>Demo</button>
                            </a>
                            <a href="https://github.com/milosadnadjevic/my-cripto-buddy">
                                <button className='text-center rounde-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>Code</button>
                            </a>
                        </div>
                    </div>
                </div>

                <div style={{backgroundImage: `url(${dashboard})`}}
                className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div'>

                    {/* Hover Effect */}
                    <div className='opacity-0 group-hover:opacity-100'>
                        <span className='text-2xl font-bold text-white tracking-wider'>
                       E-commerce Dashboard
                       </span>
                       <br></br>
                       <span className='text-sm text-white tracking-wider'>
                        JavaScript/React/Tailwind Application
                        </span>
                        <div className='pt-8 text-center'>
                            <a href="https://milos-dashboard.com">
                                <button className='text-center rounde-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>Demo</button>
                            </a>
                            <a href="https://github.com/milosadnadjevic/dashboard">
                                <button className='text-center rounde-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>Code</button>
                            </a>
                        </div>
                    </div>
                </div>

                <div style={{backgroundImage: `url(${weather})`}}
                className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div'>

                    {/* Hover Effect */}
                    <div className='opacity-0 group-hover:opacity-100'>
                    <span className='text-2xl font-bold text-white tracking-wider'>
                       Tell-me-the-weather
                       </span>
                       <br></br>
                        <span className='text-sm text-white tracking-wider'>
                    JavaScript / React Application
                        </span>
                        <div className='pt-8 text-center'>
                            <a href="https://tellmetheweather.online">
                                <button className='text-center rounde-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>Demo</button>
                            </a>
                            <a href="https://github.com/milosadnadjevic/weather-app">
                                <button className='text-center rounde-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>Code</button>
                            </a>
                        </div>
                    </div>
                </div>

                <div style={{backgroundImage: `url(${getFit})`}}
                className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div'>

                    {/* Hover Effect */}
                    <div className='opacity-0 group-hover:opacity-100'>
                    <span className='text-2xl font-bold text-white tracking-wider'>
                       Get-Fit-App
                       </span><br />
                        <span className='text-sm font-bold text-white tracking-wider'>
                        JavaScript/HTML/CSS Application
                        </span>
                        <div className='pt-8 text-center'>
                            <a href="https://get-fit-app.herokuapp.com/">
                                <button className='text-center rounde-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>Demo</button>
                            </a>
                            <a href="https://github.com/milosadnadjevic/get-fit-app/tree/master">
                                <button className='text-center rounde-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>Code</button>
                            </a>
                        </div>
                    </div>
                </div>
                <div style={{backgroundImage: `url(${socialize})`}}
                className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div'>

                    {/* Hover Effect */}
                    <div className='opacity-0 group-hover:opacity-100'>
                    <span className='text-2xl font-bold text-white tracking-wider'>
                       Socialize
                       </span><br />
                        <span className='text-sm font-bold text-white tracking-wider'>
                    JavaScript/ReactJS/NodeJS App <br /> <span className='text-sm font-light'>* backend is currently being updated</span>
                        </span>
                        <div className='pt-8 text-center'>
                            <a href="https://socialize-app-ma.netlify.app/">
                                <button className='text-center rounde-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>Demo</button>
                            </a>
                            <a href="https://github.com/milosadnadjevic/Socialize-frontend-1">
                                <button className='text-center rounde-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>Code</button>
                            </a>
                        </div>
                    </div>
                </div>

                <div style={{backgroundImage: `url(${wandering})`}}
                className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div'>

                    {/* Hover Effect */}
                    <div className='opacity-0 group-hover:opacity-100'>
                        <span className='text-2xl font-bold text-white tracking-wider'>
                    Python/Django Application
                        </span>
                        <div className='pt-8 text-center'>
                            <a href="https://wandering-app.herokuapp.com/">
                                <button className='text-center rounde-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>Demo</button>
                            </a>
                            <a href="https://github.com/milosadnadjevic/wandering-app">
                                <button className='text-center rounde-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>Code</button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
  )
}

export default Work